import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import C from 'constants/Constants';
import { BEST_CARD_LIMIT_DEFAULT } from 'constants/BestCard';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useApiUtils } from 'utils/api/api';
import { useFlowUtils } from 'utils/flow/flow';
import { useApplicationApi } from 'api/application';

import { useDocumentsApi } from 'api/documents';
import { Link, Text, Title } from 'components/Atoms/Text';
import { usePpiUtils } from 'utils/ppi/ppi';
import Form from 'components/Molecules/Form/Form';
import { Col, Row } from 'components/Atoms/Grid';
import { CheckboxInput } from 'components/Atoms/Form';
import Modal from 'components/Atoms/Modal/Modal';
import Divider from 'components/Atoms/Divider/Divider';

import Autofill from 'components/Dev/Autofill/Autofill';
import SvgDownloadLink from 'components/Atoms/SVG/Icons/SvgDownloadLink';
import SvgIllustrationHandHoldingCoin from 'components/Atoms/SVG/Illustrations/SvgIllustrationHandHoldingCoin';
import SvgSavingAccountEuro from 'components/Atoms/SVG/Icons/SvgSavingAccountEuro';
import SvgIllustrationDefaultCard from 'components/Atoms/SVG/Illustrations/SvgIllustrationDefaultCard';
import SvgIllustrationSecondaryCard from 'components/Atoms/SVG/Illustrations/SvgIllustrationSecondaryCard';
import AllowAdvertisingModalContent
	from 'components/Atoms/Partials/Content/AllowAdvertisingModalContent/AllowAdvertisingModalContent';
import SchufaModalContent from 'components/Atoms/Partials/Content/SchufaModalContent/SchufaModalContent';
import sanVisaSrc from 'assets/img/san_visa.png';
import sanSparbriefSrc from 'assets/img/sparbrief_account_success.png';
import bestCardBasicSrc from 'assets/img/best_card_basic.png';
import bestCardSmartSrc from 'assets/img/best_card_smart.png';
import bestCardExtraSrc from 'assets/img/best_card_extra.png';
import bestCardPremiumSrc from 'assets/img/best_card_premium.png';

import { useDispatch, useSelector } from 'react-redux';
import {
	setAcceptTermsAndConditions,
	setOwnResponsibility,
	setConfirmStockBasicInfo,
	setAcceptSchufa,
	setAcceptPpi,
	setAllowAdvertising,
	setSaAcceptTermsAndConditions
} from 'store/Consent/actions';
import { setGtmStatus, setGtmSubStatus } from 'store/Tracking/actions';

import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = () => {
	const { getCurrentFlow, getProductType, isCreditOrCreditCardSubType, isCreditSubType, isCreditCardSubType, isStaticSubType } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid, setApplicationInfoGtmSubStatus, ecommercePurchase } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { showPpiPage } = usePpiUtils();
	const { isCreditErgoSanc, isCreditAuxMoneySanc, isGiroErgoUid, isGmkHyundai, gmkHyundaiMsgPrefix } = useFlowUtils();
	const { getApiBaseURL } = useApiUtils();
	const { postApplication } = useApplicationApi();
	const { getDepotBasisInfo } = useDocumentsApi();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeAcceptTermsAndConditions = useSelector((state => state.consent.acceptTermsAndConditions));
	const storeOwnResponsibility = useSelector((state => state.consent.ownResponsibility));
	const storeConfirmStockBasicInfo = useSelector((state => state.consent.confirmStockBasicInfo));
	const storeAcceptSchufa = useSelector((state => state.consent.acceptSchufa));
	const storeAcceptPpi = useSelector((state => state.consent.acceptPpi));
	const storeAllowAdvertising = useSelector((state => state.consent.allowAdvertising));

	const storeDispoAmount = useSelector((state => state.dispo.amount));
	const storeOccupation = useSelector((state => state.occupation.occupation));
	const storeHasSecondApplicant = useSelector((state => state.secondApplicant.hasSecondApplicant));
	const storeHasPpi = useSelector((state => state.insurance.hasPpi));
	const storeReferenceIbanSantander = useSelector((state) => state.bankAccount.referenceIbanSantander);

	const storeRequestedBestCardLimit = useSelector((state => state.bestCard.requestedLimit ?? BEST_CARD_LIMIT_DEFAULT));

	const storeGtmStep = useSelector((state) => state.tracking.gtmStep);

	const [acceptErgo, setAcceptErgo] = useState(false);

	const [acceptTermsAndConditionsError, setAcceptTermsAndConditionsError] = useState(null);
	const [ownResponsibilityError, setOwnResponsibilityError] = useState(null);
	const [confirmStockBasicInfoError, setConfirmStockBasicInfoError] = useState(null);
	const [acceptSchufaError, setAcceptSchufaError] = useState(null);
	const [acceptPpiError, setAcceptPpiError] = useState(null);
	const [allowAdvertisingError, setAllowAdvertisingError] = useState(null);
	const [acceptErgoError, setAcceptErgoError] = useState(null);

	const [schufaPopUpIsOpen, setSchufaPopUpIsOpen] = useState(false);
	const [allowAdvertisingPopUpIsOpen, setAllowAdvertisingPopUpIsOpen] = useState(false);

	const hasTitleCols = currentFlow !== C.FRONTEND.DEPOT;
	const showOwnResponsibility = !isCreditSubType();
	const showConfirmStockBasicInfo = currentFlow === C.FRONTEND.DEPOT;
	const showAcceptSchufa = !isStaticSubType(null, [C.FRONTEND.GIRO]) && !isCreditSubType();
	const showAcceptPpi = isCreditOrCreditCardSubType() && storeHasPpi;
	const [showAllowAdvertising] = useState(!storeAllowAdvertising);
	const showHint =
		(
			!isCreditOrCreditCardSubType() &&
			currentFlow !== C.FRONTEND.GMK &&
			currentFlow !== C.FRONTEND.DEPOT
		) || isGmkHyundai;

	useEffect(() => {
		if (isCreditSubType()) {
			if (storeHasPpi) {
				setApplicationInfoGtmSubStatus('ppi');
			} else {
				setApplicationInfoGtmSubStatus('ppi_none');
			}
		}
	}, [storeGtmStep]);

	const clearErrors = () => {
		setAcceptTermsAndConditionsError(null);
		setOwnResponsibilityError(null);
		setConfirmStockBasicInfoError(null);
		setAcceptSchufaError(null);
		setAcceptPpiError(null);
		setAllowAdvertisingError(null);
		setAcceptErgoError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(storeAcceptTermsAndConditions)) {
			setAcceptTermsAndConditionsError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (showOwnResponsibility && isEmpty(storeOwnResponsibility)) {
			setOwnResponsibilityError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (showConfirmStockBasicInfo && isEmpty(storeConfirmStockBasicInfo)) {
			setConfirmStockBasicInfoError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (showAcceptSchufa && isEmpty(storeAcceptSchufa)) {
			setAcceptSchufaError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (showAcceptPpi && isEmpty(storeAcceptPpi)) {
			setAcceptPpiError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (isCreditErgoSanc && isEmpty(acceptErgo)) {
			setAcceptErgoError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postApplication(
				onSuccess,
				onError
			);
		} else {
			continueInvalid();
		}
	};

	const onSuccess = (data) => {
		let nextPage = C.ROUTES.THANKYOU;

		if (currentFlow === C.FRONTEND.GIRO) {
			if (
				typeof data !== 'undefined' &&
				typeof data.vcJwtToken !== 'undefined'
			) {
				continueValid();
				nextPage = C.ROUTES.AFTER_PURCHASE_GIRO;
				ecommercePurchase();
			} else {
				nextPage = C.ROUTES.THANKYOU3;
				onApplicationSuccessInvalid(data);
			}
		} else if (isStaticSubType(null, [C.FRONTEND.GIRO])) {
			if (
				typeof data !== 'undefined' &&
				typeof data.vcJwtToken !== 'undefined'
			) {
				continueValid();
				nextPage = C.ROUTES.IDENTIFICATION;
				ecommercePurchase();
			} else {
				onApplicationSuccessInvalid(data);
			}
		} else if (isCreditCardSubType()) {
			if (
				typeof data !== 'undefined' &&
				typeof data.vcJwtToken !== 'undefined'
			) {
				continueValid();
				nextPage = C.ROUTES.BCO_RESPONSE_GREEN;
				if (
					process.env.REACT_APP_ENABLE_BEST_CARD_LIMIT === 'true' &&
					isCreditCardSubType(null, [C.FRONTEND.VISACARD])
					&& data.bcoResult?.approvedBestCardLimit
					&& storeRequestedBestCardLimit
					&& parseInt(data.bcoResult?.approvedBestCardLimit) !== parseInt(storeRequestedBestCardLimit)
				) {
					nextPage = C.ROUTES.BCO_RESPONSE_GREEN_LIMIT;
				}
				ecommercePurchase();
			} else {
				if (currentFlow === C.FRONTEND.VISACARD) {
					nextPage = C.ROUTES.THANKYOU2;
				}
				onApplicationSuccessInvalid(data);
			}
		} else if (isCreditSubType()) {
			if (
				typeof data !== 'undefined' &&
				typeof data.vcJwtToken !== 'undefined'
			) {
				continueValid();
				if (data.bcoResult && data.bcoResult.skipContract) {
					console.log(`skipContract: ${data.bcoResult.skipContract}`);
					nextPage = C.ROUTES.THANKYOU2;
				} else {
					console.log('routing to BCO_RESPONSE_GREEN');
					nextPage = C.ROUTES.BCO_RESPONSE_GREEN;
				}
			} else {
				onApplicationSuccessInvalid(data);
			}
		} else if (
			typeof data !== 'undefined' &&
			typeof data.redirectVcUrl !== 'undefined'
		) {
			console.log('redirectVcUrl call');
			window.location.href = data.redirectVcUrl;
		} else {
			console.log('invalid call');
			onApplicationSuccessInvalid(data);
		}

		goToPage(currentFlow + nextPage);
	};

	const onApplicationSuccessInvalid = (data) => {
		continueInvalid();
		if (data) {
			if (data.resultCode === 'bco_approved') {
				dispatch(setGtmStatus('bco_response'));
				dispatch(setGtmSubStatus('bco_yellow'));
			} else if (data.resultCode === 'bco_rejected') {
				dispatch(setGtmStatus('bco_response'));
				dispatch(setGtmSubStatus('bco_red'));
			} else {
				dispatch(setGtmStatus('application_end'));
				dispatch(setGtmSubStatus('no_response_data'));
			}
		}
	};

	const onError = (res) => {
		let code = res.status;
		let nextPage = C.ROUTES.THANKYOU;
		if (res) {
			let status = 'application_end';
			let substatus = res.status;

			if (code === 409) {
				nextPage = C.ROUTES.LOGIN;
			} else if (isStaticSubType() && code === 406) {
				nextPage = C.ROUTES.THANKYOU_PEP;
			} else if (!isCreditSubType() && code === 421) {
				status = 'schufa_failed';
			} else if (currentFlow === C.FRONTEND.GIRO && code === 412) {
				nextPage = C.ROUTES.THANKYOU2;
			}
			// TODO: Wieder rein nehmen
			/* else if (isCreditSubType() && res.data?.resultCode !== 'bco_approved' && res.data?.resultCode !== 'bco_rejected') {
				nextPage = C.ROUTES.ERROR_TECHNICAL_APP;
			}*/

			if (currentFlow === C.FRONTEND.VISACARD) {
				nextPage = C.ROUTES.THANKYOU2;
			}

			if (res.data) {
				if (
					isCreditOrCreditCardSubType() &&
					(res.data.resultCode === 'bco_approved' || res.data.resultCode === 'bco_rejected')
				) {
					status = 'bco_response';
					substatus = res.data.resultCode === 'bco_approved' ? 'bco_yellow' : 'bco_red';
				}
			}

			dispatch(setGtmStatus(status));
			dispatch(setGtmSubStatus(substatus));
		}
		continueInvalid();
		goToPage(currentFlow + nextPage);
	};

	const hasPrevButton = () => {
		if (
			process.env.REACT_APP_ENABLE_BEST_CARD_LIMIT === 'true' &&
			isCreditCardSubType(null, [C.FRONTEND.VISACARD, C.FRONTEND.BEST_CARD_BASIC, C.FRONTEND.BEST_CARD_SMART]) &&
			storeRequestedBestCardLimit !== 2000
		) {
			return false;
		}
		return true;
	};

	const onPrevButton = () => {
		let prevPage;
		switch (currentFlow) {
			case C.FRONTEND.GIRO:
				prevPage = storeDispoAmount > 0 ? C.ROUTES.FINANCIAL_STATUS : C.ROUTES.DISPO;
				break;
			case C.FRONTEND.GMK:
			case C.FRONTEND.SPARBRIEF:
				prevPage = C.ROUTES.TIN;
				break;
			case C.FRONTEND.DEPOT:
				prevPage = C.ROUTES.CLEARING_ACCOUNT;
				break;
			case C.FRONTEND.BEST_CARD_BASIC:
			case C.FRONTEND.BEST_CARD_SMART:
			case C.FRONTEND.BEST_CARD_EXTRA:
			case C.FRONTEND.BEST_CARD_PREMIUM:
				if (showPpiPage()) {
					prevPage = C.ROUTES.PAYMENT_PROTECTION_INSURANCE;
				} else {
					prevPage = C.ROUTES.FINANCIAL_STATUS;
				}
				break;
			case C.FRONTEND.VISACARD:
				prevPage = showPpiPage() ? C.ROUTES.PAYMENT_PROTECTION_INSURANCE : C.ROUTES.BANK_ACCOUNT;
				break;
			case C.FRONTEND.CREDIT:
				prevPage = showPpiPage() ? C.ROUTES.PAYMENT_PROTECTION_INSURANCE : C.ROUTES.EXTERNAL_CREDIT;
				break;
			default:
				prevPage = C.ROUTES.TAX_INFORMATION;
		}
		goToPage(currentFlow + prevPage);
	};

	const getTitleGraphic = () => {
		switch (currentFlow) {
			case C.FRONTEND.GIRO:
				return <img src={sanVisaSrc} alt="Santander Giro" />;
			case C.FRONTEND.GMK:
				return (
					<div className={styles.titleGraphicSmall}>
						<SvgSavingAccountEuro color="#9E3667" />
					</div>
				);
			case C.FRONTEND.SPARBRIEF:
				return (
					<img
						src={sanSparbriefSrc}
						alt="Santander Sparbrief"
					/>
				);
			case C.FRONTEND.DEPOT:
				return <SvgIllustrationDefaultCard />;
			case C.FRONTEND.BEST_CARD_BASIC:
				return (
					<img
						src={bestCardBasicSrc}
						alt="Santander BestCard Basic"
					/>
				);
			case C.FRONTEND.BEST_CARD_SMART:
				return (
					<img
						src={bestCardSmartSrc}
						alt="Santander BestCard Smart"
					/>
				);
			case C.FRONTEND.BEST_CARD_EXTRA:
				return (
					<img
						src={bestCardExtraSrc}
						alt="Santander BestCard Extra"
					/>
				);
			case C.FRONTEND.BEST_CARD_PREMIUM:
				return (
					<img
						src={bestCardPremiumSrc}
						alt="Santander BestCard Premium"
					/>
				);
			case C.FRONTEND.VISACARD:
				return <SvgIllustrationSecondaryCard />;
			case C.FRONTEND.CREDIT:
				return <SvgIllustrationHandHoldingCoin />;
			default:
				return <SvgIllustrationDefaultCard />;
		}
	};

	const getAcceptTermsAndConditionsLabel = (occupation) => {
		const productType = getProductType();

		let link = `${getApiBaseURL()}/terms-and-conditions/${productType}`;
		if (productType === C.PRODUCT_TYPE.CREDIT && storeHasPpi) {
			link += '_PPI';
		} else if (
			(productType === C.PRODUCT_TYPE.BEST_CARD_BASIC ||
				productType === C.PRODUCT_TYPE.BEST_CARD_SMART ||
				productType === C.PRODUCT_TYPE.BEST_CARD_EXTRA ||
				productType === C.PRODUCT_TYPE.BEST_CARD_PREMIUM ||
				productType === C.PRODUCT_TYPE.VISACARD) &&
			occupation === 'STUDENT'
		) {
			link += '_STUDENT';
		} else if (
			(productType === C.PRODUCT_TYPE.BEST_CARD_BASIC ||
				productType === C.PRODUCT_TYPE.BEST_CARD_SMART ||
				productType === C.PRODUCT_TYPE.BEST_CARD_EXTRA ||
				productType === C.PRODUCT_TYPE.BEST_CARD_PREMIUM ||
				productType === C.PRODUCT_TYPE.VISACARD) &&
			storeHasPpi
		) {
			link += '_PPI';
		} else if (productType === C.PRODUCT_TYPE.GMK && isGmkHyundai) {
			link += '_HYUNDAI';
		}

		return (
			<>
				{currentFlow === C.FRONTEND.DEPOT && (
					<>
						{m('termsAndConditions.acceptTermsAndConditions.descriptionStart', currentFlow)}
						<Link
							href="/pdf/rahmenvereinbarung_blanko.pdf"
							target="_blank"
							download
						>
							{m('termsAndConditions.acceptTermsAndConditions.descriptionLink', currentFlow)}
						</Link>
					</>
				)}
				{m('termsAndConditions.acceptTermsAndConditions.description', currentFlow)}
				<br />
				<Link href={link} target="_blank">
					{m(`${gmkHyundaiMsgPrefix}termsAndConditions.acceptTermsAndConditions.linkText`, currentFlow)}
					<SvgDownloadLink />
				</Link>
			</>
		);
	};

	const autofill = () => {
		dispatch(setAcceptTermsAndConditions(true));
		if (showOwnResponsibility) {
			dispatch(setOwnResponsibility(true));
		}
		if (showConfirmStockBasicInfo) {
			dispatch(setConfirmStockBasicInfo(true));
		}
		if (showAcceptSchufa) {
			dispatch(setAcceptSchufa(true));
		}
		if (showAcceptPpi) {
			dispatch(setAcceptPpi(true));
		}
		if (storeHasSecondApplicant) {
			dispatch(setSaAcceptTermsAndConditions(true));
		}

		dispatch(setAllowAdvertising(false));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Row>
				<Col xs={12} sm={hasTitleCols ? 6 : 12} md={hasTitleCols ? 7 : 12}>
					<Title>{m('pages.termsAndConditions.title', 'global')}</Title>
				</Col>

				{hasTitleCols && (
					<Col xs={12} sm={6} md={5}>
						<div className={classNames(
							styles.titleGraphic,
							currentFlow === C.FRONTEND.GIRO || isCreditCardSubType() ? styles.titleGraphicHideMobile : null
						)}>
							{getTitleGraphic()}
						</div>
					</Col>
				)}
			</Row>

			<Divider />

			<Form
				onSubmit={onSubmit}
				submitText={m(`${gmkHyundaiMsgPrefix}termsAndConditions.submit`, currentFlow)}
				clearErrors={clearErrors}
				submitTabindex={8}
				onPrevButton={hasPrevButton() ? () => onPrevButton() : null}
			>
				<CheckboxInput
					value={storeAcceptTermsAndConditions}
					setValue={(value) => { dispatch(setAcceptTermsAndConditions(value)); }}
					label={getAcceptTermsAndConditionsLabel(storeOccupation)}
					hasError={!!acceptTermsAndConditionsError}
					message={acceptTermsAndConditionsError}
					tabindex={1}
					testId="accept-terms-and-conditions"
				/>

				{showOwnResponsibility && (
					<CheckboxInput
						value={storeOwnResponsibility}
						setValue={(value) => { dispatch(setOwnResponsibility(value)); }}
						label={m('ownResponsibility.description', 'fields')}
						hasError={!!ownResponsibilityError}
						message={ownResponsibilityError}
						tabindex={2}
						testId="own-responsibility"
					/>
				)}

				{showConfirmStockBasicInfo && (
					<CheckboxInput
						value={storeConfirmStockBasicInfo}
						setValue={(value) => { dispatch(setConfirmStockBasicInfo(value)); }}
						label={
							<>
								{m('confirmStockBasicInfo.description', 'fields')}
								<Link onClick={async () => { await getDepotBasisInfo(); }}>
									{m('confirmStockBasicInfo.linkText', 'fields')}{' '}
									<SvgDownloadLink />
								</Link>
							</>
						}
						hasError={!!confirmStockBasicInfoError}
						message={confirmStockBasicInfoError}
						tabindex={3}
						testId="confirm-stock-basic-info"
					/>
				)}

				{showAcceptSchufa && (
					<>
						<CheckboxInput
							value={storeAcceptSchufa}
							setValue={(value) => { dispatch(setAcceptSchufa(value)); }}
							label={
								<>
									{m('acceptSchufa.description1', 'fields')}{' '}
									<Link onClick={() => { setSchufaPopUpIsOpen(true); }}>
										{m('acceptSchufa.linkText', 'fields')}
									</Link>{' '}
									{m('acceptSchufa.description2', 'fields')}
								</>
							}
							hasError={!!acceptSchufaError}
							message={acceptSchufaError}
							tabindex={4}
							testId="accept-schufa"
						/>
						<Modal
							isOpen={schufaPopUpIsOpen}
							onClose={() => { setSchufaPopUpIsOpen(false); }}
						>
							<SchufaModalContent />
						</Modal>
					</>
				)}

				{showAcceptPpi && (
					<CheckboxInput
						value={storeAcceptPpi}
						setValue={(value) => { dispatch(setAcceptPpi(value)); }}
						label={
							<>
								{m('paymentProtectionInsurance.description', 'fields')}
								<br />
								<Link href="https://www.santander.de/content/pdf/informationen/informationsblatt-datenverarbeitung-und-weitergabe-fuer-rsv.pdf" target="_blank">
									{m('paymentProtectionInsurance.linkText1', 'fields')}
									<SvgDownloadLink />
								</Link>
								<br />
								<Link href="https://www.santander.de/content/pdf/informationen/statusbezogene-informationen-versvermv.pdf" target="_blank">
									{m('paymentProtectionInsurance.linkText2', 'fields')}
									<SvgDownloadLink />
								</Link>
							</>
						}
						hasError={!!acceptPpiError}
						message={acceptPpiError}
						tabindex={5}
						testId="accept-ppi"
					/>
				)}

				{isCreditErgoSanc && (
					<>
						<CheckboxInput
							value={acceptErgo}
							setValue={setAcceptErgo}
							label={m('acceptErgo.description', 'fields')}
							hasError={!!acceptErgoError}
							message={acceptErgoError}
							tabindex={6}
							testId="accept-ergo"
						/>
					</>
				)}

				{showAllowAdvertising && !isCreditErgoSanc && !isCreditAuxMoneySanc && !isGiroErgoUid && (
					<>
						<CheckboxInput
							value={storeAllowAdvertising}
							setValue={(value) => { dispatch(setAllowAdvertising(value)); }}
							label={
								<>
									{m('allowAdvertising.description', 'fields')}{' '}
									<Link onClick={() => { setAllowAdvertisingPopUpIsOpen(true); }}>
										{m('allowAdvertising.linkText', 'fields')}
									</Link>
								</>
							}
							hasError={!!allowAdvertisingError}
							message={allowAdvertisingError}
							tabindex={7}
							testId="allow-advertising"
						/>
						<Modal
							isOpen={allowAdvertisingPopUpIsOpen}
							onClose={() => { setAllowAdvertisingPopUpIsOpen(false); }}
						>
							<AllowAdvertisingModalContent />
						</Modal>
					</>
				)}

				{isCreditSubType() && (
					<div className="mt--15">
						<Text>{m('acceptSchufa.summary', 'fields', null, true)}</Text>
					</div>
				)}


				{currentFlow === C.FRONTEND.DEPOT && !storeReferenceIbanSantander && (
					<div className="mt--30">
						<Text>
							{m(
								'termsAndConditions.hint',
								currentFlow,
								null,
								true
							)}
						</Text>
					</div>
				)}

				{showHint && (
					<>
						<Divider />
						<Text mb={isGmkHyundai}>{m(`${gmkHyundaiMsgPrefix}termsAndConditions.summary`, currentFlow, null, true)}</Text>
						{isGmkHyundai && (
							<Text>{m(`${gmkHyundaiMsgPrefix}termsAndConditions.summaryHint`, currentFlow)}</Text>
						)}
					</>
				)}
			</Form>
		</>
	);
};
export default TermsAndConditions;
