import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useNumberUtils } from 'utils/number/number';

import { Text } from 'components/Atoms/Text';
import ToggleLink from 'components/Atoms/Partials/ToggleLink/ToggleLink';

import styles from './CreditDetails.module.scss';

const CreditDetails = props => {
	const { formatMoneyValue } = useNumberUtils();

	const [isCreditDetailsOpen, setIsCreditDetailsOpen] = useState(false);

	return (
		<>
			<ToggleLink
				active={isCreditDetailsOpen}
				setActive={() => {
					setIsCreditDetailsOpen(!isCreditDetailsOpen);
				}}
				size="l"
			>
				{m('creditDetails', 'fields')}
			</ToggleLink>
			<div
				className={classNames([
					styles.upsellDownsellMoreDetailsWrapper,
					isCreditDetailsOpen
						? styles.upsellDownsellMoreDetailsWrapperActive
						: null
				])}
			>
				<div className={styles.detailsTable}>
					<div className={styles.detailsTableRow}>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellLabel
							])}
						>
							<Text size="s"><strong>{m('runtime.label', 'fields')}:</strong></Text>
						</div>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellValue
							])}
							data-testid='runtime'
						>
							<Text size="s">{props.runtimeInMonths + ' Monate'}</Text>
						</div>
					</div>
					{!!props.effectiveAnnualInterestRate && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								<Text size="s"><strong>{m('effectiveYearlyInterest.label', 'fields')}:</strong></Text>
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='effectiveAnnualInterestRate'
							>
								<Text size="s">{`${formatMoneyValue(props.effectiveAnnualInterestRate, true)} %`}</Text>
							</div>
						</div>
					)}
					{!!props.fixedBorrowingRate && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								<Text size="s"><strong>{m('fixedBorrowingRate.label', 'fields')}:</strong></Text>
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='fixed-borrowing-rate'
							>
								<Text size="s">{`${formatMoneyValue(props.fixedBorrowingRate, true)} %`}</Text>
							</div>
						</div>
					)}
					{!!props.firstRateDate && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								<Text size="s"><strong>{m('firstRate.date', 'fields')}:</strong></Text>
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='first-rate-date'
							>
								<Text size="s">{props.firstRateDate}</Text>
							</div>
						</div>
					)}
					{!!props.interest && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								<Text size="s"><strong>{m('interest.label', 'fields')}:</strong></Text>
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='interest'
							>
								<Text size="s">{`${formatMoneyValue(props.interest, true)} €`}</Text>
							</div>
						</div>
					)}
					<div className={styles.detailsTableRow}>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellLabel
							])}
						>
							<Text size="s"><strong>{m('paymentProtectionInsurance.labelLong', 'fields')}:</strong></Text>
						</div>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellValue
							])}
							data-testid='payment-protection-insurance'
						>
							<Text size="s">
								{!props.ppiAmount || props.ppiAmount === 0
								? m('none', 'fields')
								: `${formatMoneyValue(props.ppiAmount, true)} €`}
							</Text>
						</div>
					</div>

					<div className={styles.detailsTableRow}>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellLabel
							])}
						>
							<Text size="s"><strong>{m('protect.label', 'fields')}:</strong></Text>
						</div>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellValue
							])}
							data-testid='protect'
						>
							<Text size="s">
								{!props.protectAmount || props.protectAmount === 0
									? 'kein'
									: `${formatMoneyValue(props.protectAmount, true)} €`}
							</Text>
						</div>
					</div>

					<div className={styles.detailsTableRow}>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellLabel
							])}
						>
							<Text size="s">
								<strong>
									{m('totalAmount.label', 'fields')}:
								</strong>
							</Text>
						</div>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellValue
							])}
							data-testid='total-amount'
						>
							<Text size="s">{`${formatMoneyValue(props.totalAmount, true)} €`}</Text>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

CreditDetails.propTypes = {
	runtimeInMonths: PropTypes.number,
	totalAmount: PropTypes.number,
	ppiAmount: PropTypes.number,
	protectAmount: PropTypes.number,
	interest: PropTypes.number,
	firstRateDate: PropTypes.string,
	effectiveAnnualInterestRate: PropTypes.number,
	fixedBorrowingRate: PropTypes.number
};

export default CreditDetails;
