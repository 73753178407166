import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 12;
	const height = 12;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="m3.43,12c-.47,0-.86-.38-.86-.85,0,0,0,0,0,0,0-.23.09-.45.25-.61l4.53-4.53L2.83,1.47c-.34-.34-.34-.88,0-1.22.33-.34.88-.34,1.21,0,0,0,0,0,0,0l5.13,5.14c.34.33.34.88,0,1.21,0,0,0,0,0,0l-5.13,5.14c-.16.16-.38.25-.61.25Z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
