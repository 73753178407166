import React, { useEffect, useMemo, useRef, useState } from 'react';
import { m } from 'utils/messages/messages';
import C from 'constants/Constants';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { IBAN_INPUT, NUMBER_INPUT } from 'constants/Regex';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { usePageUtils } from 'utils/page/page';
import { useScrollUtils } from 'utils/scroll/scroll';
import { useViewUtils } from 'utils/view/view';
import { useItemsUtils } from 'utils/items/items';

import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { TextInput, DropdownInput, RadioBoxInput } from 'components/Atoms/Form';
import Divider from 'components/Atoms/Divider/Divider';
import SvgEuro from 'components/Atoms/SVG/Icons/SvgEuro';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setIncome, setAdditionalIncome, setExpenditure, setOtherExpenses, setTypeOfLiving } from 'store/FinancialStatus/actions';

import { setReferenceIban } from 'store/BankAccount/actions';
import { useIbanUtils } from 'utils/iban/iban';
import { digitalAccountCheckClear } from 'store/DigitalAccountCheck/actions';

const NewIbanDAC = () => {
	const { getCurrentFlow, isCreditCardSubType } = useUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, hasIbanError, addErrorMessage } = useValidationUtils();
	const { continueInvalid, continueValid } = useTrackingUtils();
	const { formatIbanForInput } = useIbanUtils();
	const { goToPage } = usePageUtils();
	const { scrollTo } = useScrollUtils();
	const { isMobile } = useViewUtils();
	const { getTypeOfLivingItems } = useItemsUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeHasSecondApplicant = useSelector((state => state.secondApplicant.hasSecondApplicant));
	const storeDacStatus = useSelector((state => state.digitalAccountCheck.status));
	const storeReferenceIban = useSelector((state => state.bankAccount.referenceIban));
	const storeIncome = useSelector((state => state.financialStatus.income));
	const storeAdditionalIncome = useSelector((state => state.financialStatus.additionalIncome));
	const storeExpenditure = useSelector((state => state.financialStatus.expenditure));
	const storeOtherExpenses = useSelector((state => state.financialStatus.otherExpenses));
	const storeTypeOfLiving = useSelector((state => state.financialStatus.typeOfLiving));

	const disableAutomatic = !!(storeDacStatus === C.DAC_STATUS.RED || storeDacStatus === C.DAC_STATUS.YELLOW || storeHasSecondApplicant);

	const [dacCleared, setDacCleared] = useState(false);

	const [type, setType] = useState(null);
	const [prevIban, setPrevIban] = useState(null);

	const [typeError, setTypeError] = useState(null);
	const [incomeError, setIncomeError] = useState(null);
	const [additionalIncomeError, setAdditionalIncomeError] = useState(null);
	const [expenditureError, setExpenditureError] = useState(null);
	const [otherExpensesError, setOtherExpensesError] = useState(null);
	const [typeOfLivingError, setTypeOfLivingError] = useState(null);
	const [ibanError, setIbanError] = useState(null);

	const typeOfLivingItems = getTypeOfLivingItems();

	const scrollAnchorRef = useRef(null);

	useEffect(() => {
		dispatch(digitalAccountCheckClear());
		setPrevIban(storeReferenceIban);
		setDacCleared(true);
	}, []);

	useEffect(() => {
		if (type !== null) {
			let offset = -20;
			if (isMobile()) {
				offset = 50;
			}
			scrollTo(scrollAnchorRef, offset);
		}
		if (type === 'AUTOMATIC') {
			dispatch(setReferenceIban(null));
		}
		if (type === 'MANUALLY') {
			dispatch(setReferenceIban(prevIban));
		}
	}, [type]);

	const clearErrors = () => {
		setTypeError(null);
		setIncomeError(null);
		setAdditionalIncomeError(null);
		setExpenditureError(null);
		setOtherExpensesError(null);
		setTypeOfLivingError(null);
		setIbanError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isIbanValid = true;
		let isValid = false;

		if (isEmpty(type)) {
			setTypeError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else if (type === 'MANUALLY') {
			if (isEmpty(storeIncome, true)) {
				setIncomeError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
			if (isEmpty(storeExpenditure, true)) {
				setExpenditureError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
			if (isEmpty(storeTypeOfLiving)) {
				setTypeOfLivingError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
			isValid = !hasEmptyFields;
		} else {
			if (isEmpty(storeReferenceIban)) {
				setIbanError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			} else {
				isIbanValid = !(hasIbanError(storeReferenceIban));
				if (!isIbanValid) {
					setIbanError(m('validation.error.iban.invalid', 'fields'));
				}
			}

			if (!hasEmptyFields && !isIbanValid) {
				addErrorMessage(
					FormErrorCode.INVALID_FIELDS,
					m('validation.error.hasInvalidFields', 'fields')
				);
			}

			isValid = !hasEmptyFields && isIbanValid;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		if (type === 'AUTOMATIC') {
			goToPage(currentFlow + C.ROUTES.DIGITAL_ACCOUNT_CHECK, '?redoInitCall=true');
		} else if (type === 'MANUALLY') {
			if (isCreditCardSubType()) {
				goToPage(currentFlow + C.ROUTES.PAYMENT_PROTECTION_INSURANCE);
			} else {
				goToPage(currentFlow + C.ROUTES.EXTERNAL_CREDIT);
			}
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const typeItems = useMemo(() => {
		const items = [
			{
				value: 'MANUALLY',
				content: (
					<Text mb>
						<strong>{m('pages.newIbanDAC.manually.box.title', 'global')}</strong>
					</Text>
				)
			}
		];
		if (!disableAutomatic) {
			items.unshift({
				value: 'AUTOMATIC',
				content: (
					<Text mb>
						<strong>{m('pages.newIbanDAC.automatic.box.title', 'global')}</strong>
					</Text>
				)
			});
		}
		return items;
	}, [disableAutomatic]);

	const autofill = () => {
		setType('AUTOMATIC');
		dispatch(setReferenceIban(C.DUMMY_IBAN_2));
	};


	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.newIbanDAC.title', 'global')}</Title>
			{dacCleared && (
				<Form
					onSubmit={onSubmit}
					clearErrors={clearErrors}
					submitTabindex={7}
				>
					<Row>
						<Col xs={12}>
							<RadioBoxInput
								value={type}
								setValue={setType}
								items={typeItems}
								hasEqualHeight
								hasError={!!typeError}
								message={typeError}
								tabindex={1}
								testId="dac-type-new-iban"
							/>
						</Col>
						<div className="anchor" ref={scrollAnchorRef} />
						{type === 'AUTOMATIC' && (
							<Col xs={12}>
								<Divider noLine />
								<Title size="s" mb>{m('pages.newIbanDAC.automatic.description.title', 'global')}</Title>
								<Text mb>{m('pages.newIbanDAC.automatic.description.description', 'global')}</Text>
								<TextInput
									value={storeReferenceIban}
									setValue={(value) => { dispatch(setReferenceIban(value)); }}
									label={m('iban.label', 'fields')}
									hasError={!!ibanError}
									message={ibanError ?? m('iban.placeholder', 'fields')}
									regex={IBAN_INPUT}
									format={formatIbanForInput}
									maxLength={40}
									tabindex={1}
									testId="iban"
								/>
							</Col>
						)}
						{type === 'MANUALLY' && (
							<>
								<Col xs={12}>
									<Divider noLine />
									<Title size="s" mb>{m('pages.financialStatusDAC.manually.description.title', 'global')}</Title>
								</Col>
								<Col xs={12} sm={6}>
									<TextInput
										value={storeIncome || storeIncome === 0 ? storeIncome + '' : null}
										setValue={(value) => { dispatch(setIncome(value)); }}
										label={m('income.labelWithoutNet', 'fields')}
										hasError={!!incomeError}
										message={incomeError ?? m('income.placeholder', 'fields')}
										regex={NUMBER_INPUT}
										tooltip={
											<>
												<strong>{m('income.labelWithoutNet', 'fields')}</strong>
												<br />
												{m('income.tooltip', 'fields')}
											</>
										}
										suffix={<SvgEuro />}
										tabindex={2}
										testId="income"
									/>
								</Col>
								<Col xs={12} sm={6}>
									<TextInput
										value={storeAdditionalIncome || storeAdditionalIncome === 0 ? storeAdditionalIncome + '' : null}
										setValue={(value) => { dispatch(setAdditionalIncome(value)); }}
										label={m('additionalIncome.monthlyNet', 'fields')}
										hasError={!!additionalIncomeError}
										message={additionalIncomeError ?? m('additionalIncome.placeholder', 'fields')}
										regex={NUMBER_INPUT}
										tooltip={
											<>
												<strong>{m('additionalIncome.label', 'fields')}</strong>
												<br />
												{m('additionalIncome.tooltip', 'fields')}
											</>
										}
										suffix={<SvgEuro />}
										tabindex={3}
										testId="additional-income"
									/>
								</Col>
								<Col xs={12}>
									<DropdownInput
										value={storeTypeOfLiving}
										setValue={(value) => { dispatch(setTypeOfLiving(value)); }}
										items={typeOfLivingItems}
										smallItemBox
										label={m('typeOfLiving.label', 'fields')}
										hasError={!!typeOfLivingError}
										message={typeOfLivingError}
										tabindex={4}
										testId="type-of-living"
									/>
								</Col>
								<Col xs={12} sm={6}>
									<TextInput
										value={storeExpenditure || storeExpenditure === 0 ? storeExpenditure + '' : null}
										setValue={(value) => { dispatch(setExpenditure(value)); }}
										label={storeHasSecondApplicant ? m('expenditure.monthlyBoth', 'fields') : m('expenditure.monthly', 'fields')}
										hasError={!!expenditureError}
										message={expenditureError ?? m('expenditure.placeholder', 'fields')}
										regex={NUMBER_INPUT}
										suffix={<SvgEuro />}
										tabindex={5}
										testId="expenditure"
									/>
								</Col>
								<Col xs={12} sm={6}>
									<TextInput
										value={storeOtherExpenses || storeOtherExpenses === 0 ? storeOtherExpenses + '' : null}
										setValue={(value) => { dispatch(setOtherExpenses(value)); }}
										label={storeHasSecondApplicant ? m('otherExpenses.monthlyBoth', 'fields') : m('otherExpenses.monthly', 'fields')}
										hasError={!!otherExpensesError}
										message={otherExpensesError ?? m('otherExpenses.placeholder', 'fields')}
										regex={NUMBER_INPUT}
										suffix={<SvgEuro />}
										tabindex={6}
										testId="other-expenses"
									/>
								</Col>
							</>
						)}
					</Row>
				</Form>
			)}
		</>
	);
};
export default NewIbanDAC;
