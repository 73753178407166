import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { find } from 'lodash';
import axios from 'axios';
import C from 'constants/Constants';
import { CREDIT_AMOUNT_DEFAULT, CREDIT_RUNTIME_DEFAULT } from 'constants/Credit';
import { useUtils } from 'utils/utils';
import { useTargetUtils } from 'utils/target/target';
import { useCreditUtils } from 'utils/credit/credit';

import { useDispatch, useSelector } from 'react-redux';
import {
	setGtmType,
	setGtmSubType,
	setGtmStep,
	setGtmStatus,
	setGtmSubStatus,
	setGtmIdentificationDocument,
	addToCart,
	removeFromCart
} from 'store/Tracking/actions';

export const useTrackingUtils = () => {
	const { getCurrentFlow, isCreditSubType } = useUtils();
	const { initTarget } = useTargetUtils();
	const { getPpiValue, getProtectValue, isPpiSenior } = useCreditUtils();
	const { getTotalAmount } = useCreditUtils();

	const location = useLocation();
	const dispatch = useDispatch();

	const storeApplicationId = useSelector((state => state.app.applicationId));
	const storeIsRequestFromCaps = useSelector((state => state.app.isRequestFromCaps));

	const storeHasSecondApplicant = useSelector((state => state.secondApplicant.hasSecondApplicant));

	const storeSanc = useSelector((state => state.app.sanc));
	const storeUid = useSelector((state => state.app.uid));

	const storeCreditAmount = useSelector((state => state.app.amount));
	const storeCreditRuntime = useSelector((state => state.app.runtime));

	const storeCreditBcoAmount = useSelector((state => state.app.bcoResults.creditAmountNet));
	const storeCreditBcoRuntime = useSelector((state => state.app.bcoResults.runtimeInMonths));

	const storeInsuranceProtect = useSelector((state => state.insurance.protect));

	const storeInvestmentAmount = useSelector((state => state.investment.amount));
	const storeInvestmentRuntime = useSelector((state => state.investment.runtime));
	const storeInvestmentPaymentType = useSelector((state => state.investment.paymentType));

	const storeGtmType = useSelector((state => state.tracking.gtmType));
	const storeGtmSubType = useSelector((state => state.tracking.gtmSubType));
	const storeGtmStep = useSelector((state => state.tracking.gtmStep));
	const storeGtmStatus = useSelector((state => state.tracking.gtmStatus));
	const storeGtmSubStatus = useSelector((state => state.tracking.gtmSubStatus));
	const storeGtmIdentificationDocument = useSelector((state => state.tracking.gtmIdentificationDocument));

	const storeCart = useSelector((state => state.tracking.cart));
	const storeIsSantanderIban = useSelector((state => state.bankAccount.isSantanderIban));

	const [triggerECommercePurchase, setTriggerECommercePurchase] = useState(false);

	useEffect(() => {
		if (triggerECommercePurchase) {
			finishEcommercePurchase();
			setTriggerECommercePurchase(false);
		}
	}, [triggerECommercePurchase]);

	const initConsent = () => {
		if (process.env.NODE_ENV !== 'development') {
			window.addEventListener('UC_UI_INITIALIZED', function () {
				/* prettier-ignore-start */
				// @ts-ignore
				const enableGTM = window.UC_UI.getServicesBaseInfo()
					// @ts-ignore
					.filter(function (event) {
						return 'Google Tag Manager' === event.name;
					})
					.map(function (event) {
						return event.consent.status;
					})[0];
				if (enableGTM) {
					initGtm();
				}

				const enableTarget = window.UC_UI.getServicesBaseInfo()
					// @ts-ignore
					.filter(function (event) {
						return 'Adobe Experience Cloud' === event.name;
					})
					.map(function (event) {
						return event.consent.status;
					})[0];
				if (enableTarget) {
					initTarget(true);
				}
				/* prettier-ignore-end */
			});

			window.addEventListener('UC_UI_VIEW_CHANGED', function (event) {
				/* prettier-ignore-start */
				if ('PRIVACY_BUTTON' === event.detail.view && 'SECOND_LAYER' === event.detail.previousView) {
					// @ts-ignore
					window['ga-disable-UA-58567623-28'] = !event.srcElement.UC_UI.getServicesBaseInfo().filter(function (item) { // eslint-disable-line
						return 'Google Analytics' === item.name;
					}).map(function (item) {
						return item.consent.status;
					})[0], location.reload(); // eslint-disable-line
				} else {
					try {
						// @ts-ignore
						window['ga-disable-UA-58567623-28'] = !window.UC_UI.getServicesBaseInfo()
							.filter(function (event) {
								return 'Google Analytics' === event.name;
							})
							.map(function (event) {
								return event.consent.status;
							})[0];
					} catch (error) {
						// @ts-ignore
						window['ga-disable-UA-58567623-28'] = !0;
					}
					/* prettier-ignore-end */
				}

				const enableTarget = window.UC_UI.getServicesBaseInfo()
					// @ts-ignore
					.filter(function (event) {
						return 'Adobe Experience Cloud' === event.name;
					})
					.map(function (event) {
						return event.consent.status;
					})[0];
				initTarget(enableTarget);
			});
		}
	};

	const initGtm = () => {
		axios
			.get(window.location)
			.then(response => {
				if (
					response.headers &&
					response.headers.gtmid &&
					response.headers.gtmauth &&
					response.headers.gtmpreview
				) {
					TagManager.initialize({
						gtmId: response.headers.gtmid,
						auth: response.headers.gtmauth,
						preview: response.headers.gtmpreview
					});
				}
			})
			.catch(() => {});
	};

	const openCookieLayer = () => {
		try {
			window.UC_UI.showSecondLayer();
		} catch(e) {
			console.log('usercentrics not active');
		}
	};

	const setApplicationInfo = () => {
		if (!window.applicationInfo) {
			window.applicationInfo = {};
		}

		const applicationInfo = {
			type: storeGtmType ?? window.applicationInfo.type ?? null,
			subtype: storeGtmSubType ?? window.applicationInfo.subtype ?? null,
			branch: 'A',
			sanc: storeSanc ?? window.applicationInfo.sanc ?? null,
			uid: storeUid ?? window.applicationInfo.uid ?? null
		};

		applicationInfo.id = storeApplicationId ?? window.applicationInfo.id ?? null;
		applicationInfo.step = storeGtmStep ?? window.applicationInfo.step ?? null;
		applicationInfo.status = storeGtmStatus ?? null;
		applicationInfo.substatus = storeGtmSubStatus ?? null;
		applicationInfo['identification-document'] = storeGtmIdentificationDocument ?? null;
		applicationInfo.sdbCustomer = !!storeIsRequestFromCaps;
		applicationInfo.addBorrower = !!storeHasSecondApplicant ? 'two' : 'one';

		window.applicationInfo = applicationInfo;

		dispatch(setGtmType(undefined));
		dispatch(setGtmSubType(undefined));
		dispatch(setGtmStep(undefined));
		dispatch(setGtmStatus(undefined));
		dispatch(setGtmSubStatus(undefined));
		dispatch(setGtmIdentificationDocument(undefined));
	};

	const setApplicationInfoGtmStatus = (gtmStatus) => {
		if (!window.applicationInfo) {
			window.applicationInfo = {};
		}
		window.applicationInfo.status = gtmStatus;
	};

	const setApplicationInfoGtmSubStatus = (gtmSubStatus) => {
		if (!window.applicationInfo) {
			window.applicationInfo = {};
		}
		window.applicationInfo.substatus = gtmSubStatus;
	};

	/**
	 * Should be fired on page load and page change
	 */
	const virtualPageLoad = () => {
		if (location && location.pathname) {
			try {
				const tagManagerArgs = {
					dataLayer: {
						event: 'virtual_pageview',
						page_path: location.pathname
					}
				};
				TagManager.dataLayer(tagManagerArgs);
			} catch (err) {
				console.log(
					'Google Tag Manager: DataLayer.push on "event": "virtual_pageview" failed'
				);
			}
		}
	};

	/**
	 * Should be fired on valid next button
	 */
	const continueValid = () => {
		try {
			const tagManagerArgs = {
				dataLayer: {
					event: 'continue_valid'
				}
			};
			TagManager.dataLayer(tagManagerArgs);
		} catch (err) {
			console.log(
				'Google Tag Manager: DataLayer.push on "event": "continue_valid" failed'
			);
		}
	};

	/**
	 * Should be fired on invalid next button
	 */
	const continueInvalid = () => {
		try {
			const tagManagerArgs = {
				dataLayer: {
					event: 'continue_invalid'
				}
			};
			TagManager.dataLayer(tagManagerArgs);
		} catch (err) {
			console.log(
				'Google Tag Manager: DataLayer.push on "event": "continue_invalid" failed'
			);
		}
	};

	/**
	 * Should be fired on click to the previous button
	 */
	const goBack = () => {
		try {
			const tagManagerArgs = {
				dataLayer: {
					event: 'return'
				}
			};
			TagManager.dataLayer(tagManagerArgs);
		} catch (err) {
			console.log(
				'Google Tag Manager: DataLayer.push on "event": "return" failed'
			);
		}
	};

	/**
	 * Should be fired after successfull application api call
	 */
	const bcoResults = results => {
		try {
			const tagManagerArgs = {
				dataLayer: {
					event: 'bcoResults_application',
					bcoResults: results
				}
			};
			TagManager.dataLayer(tagManagerArgs);
		} catch (err) {
			console.log('Google Tag Manager: bcoResults push on dataLayer failed');
		}
	};

	const error = (statusCode = null, message = null) => {
		try {
			const tagManagerArgs = {
				dataLayer: {
					event: 'error_occured',
					status: statusCode ?? 500,
					message: message
				}
			};
			TagManager.dataLayer(tagManagerArgs);
		} catch (err) {
			console.log('Google Tag Manager: error push on dataLayer failed');
		}
	};

	/**
	 *
	 * Should be fired when there is an error with DAC
	 */
	const dacError = errorCode => {
		try {
			const tagManagerArgs = {
				dataLayer: {
					event: 'dac_error_tracking',
					errorCode
				}
			};
			TagManager.dataLayer(tagManagerArgs);
		} catch (err) {
			console.log(
				'Google Tag Manager: DataLayer.push on "event": "dac_error_tracking" failed'
			);
		}
	};

	const getEcommerceData = (customFlow) => {
		const currentFlow = customFlow ?? getCurrentFlow();
		let data = {
			currency: 'EUR',
			index: 1,
			item_brand: 'Santander',
			quantity: 1
		};
		switch (currentFlow) {
			case C.FRONTEND.CREDIT:
				data.item_id = '143_460';
				data.item_name = 'BestCredit';
				data.item_category = 'Kredit';
				data.item_category2 = 'BestCredit';
				data.item_variant = storeCreditBcoRuntime ?? storeCreditRuntime ?? CREDIT_RUNTIME_DEFAULT;
				data.price = storeCreditBcoAmount ?? storeCreditAmount ?? CREDIT_AMOUNT_DEFAULT;
				break;
			case C.FRONTEND.BEST_CARD_BASIC:
				data.item_id = '500_403';
				data.item_name = 'BestCard Basic';
				data.item_category = 'Karten';
				data.item_category2 = 'BestCard';
				data.item_category3 = 'Basic';
				break;
			case C.FRONTEND.BEST_CARD_SMART:
				data.item_id = '500_611';
				data.item_name = 'BestCard Smart';
				data.item_category = 'Karten';
				data.item_category2 = 'BestCard';
				data.item_category3 = 'Smart';
				if (storeIsSantanderIban) {
					data.item_variant = 'Giro';
					data.price = '0';
				} else {
					data.price = '24';
				}
				break;
			case C.FRONTEND.BEST_CARD_EXTRA:
				data.item_id = '500_612';
				data.item_name = 'BestCard Extra';
				data.item_category = 'Karten';
				data.item_category2 = 'BestCard';
				data.item_category3 = 'Extra';
				if (storeIsSantanderIban) {
					data.item_variant = 'Giro';
					data.price = '35';
				} else {
					data.price = '59';
				}
				break;
			case C.FRONTEND.BEST_CARD_PREMIUM:
				data.item_id = '500_613';
				data.item_name = 'BestCard Premium';
				data.item_category = 'Karten';
				data.item_category2 = 'BestCard';
				data.item_category3 = 'Premium';
				if (storeIsSantanderIban) {
					data.item_variant = 'Giro';
					data.price = '75';
				} else {
					data.price = '99';
				}
				break;
			case C.FRONTEND.VISACARD:
				data.item_id = '000';
				data.item_name = 'VisaCard';
				data.item_category = 'Karten';
				data.item_category2 = 'Visa';
				break;
			case C.FRONTEND.GIRO:
				data.item_id = '300_400';
				data.item_name = 'BestGiro';
				data.coupon = 'Bonus75';
				data.item_category = 'Girokonto';
				data.item_category2 = 'BestGiro';
				break;
			case C.FRONTEND.GMK:
				data.item_id = '301_406';
				data.item_name = 'GMK';
				data.item_category = 'Sparen';
				data.item_category2 = 'GMK';
				break;
			case C.FRONTEND.SPARBRIEF:
				const paymentType = storeInvestmentPaymentType;
				if (paymentType) {
					data.item_id = '302';
					data.item_name = 'Sparbrief';
					data.item_category = 'Sparen';
					data.item_category2 = 'Sparbrief';
					data.item_variant = storeInvestmentRuntime ?? null;
					data.price = storeInvestmentAmount ?? null;
					data.item_category3 = paymentType === 'END_OF_TERM' ? 'Jaehrlich' : 'Endfaellig';
					data.item_list_id = paymentType === 'END_OF_TERM' ? '401' : '403';
				} else {
					data = null;
				}
				break;
			case C.FRONTEND.DEPOT:
				data.item_id = '749_003';
				data.item_name = 'Depot';
				data.item_category = 'Sparen';
				data.item_category2 = 'Depot';
				break;
			case 'ppi':
				data.item_id = '315';
				data.item_name = 'RSV';
				data.item_category = 'Versicherung';
				data.item_category2 = 'RSV';
				let withRuntime = false;
				if (isCreditSubType()) {
					withRuntime = true;
				}
				if (isPpiSenior(withRuntime)) {
					data.item_category3 = 'Leben';
				} else {
					data.item_category3 = 'Komplett';
				}
				switch (getCurrentFlow()) {
					case C.FRONTEND.CREDIT:
						data.item_variant = 'BestCredit';
						break;
					case C.FRONTEND.BEST_CARD_BASIC:
						data.item_variant = 'BestCard Basic';
						break;
					case C.FRONTEND.BEST_CARD_SMART:
						data.item_variant = 'BestCard Smart';
						break;
					case C.FRONTEND.BEST_CARD_EXTRA:
						data.item_variant = 'BestCard Extra';
						break;
					case C.FRONTEND.BEST_CARD_PREMIUM:
						data.item_variant = 'BestCard Premium';
						break;
					case C.FRONTEND.VISACARD:
						data.item_variant = 'VisaCard';
						break;
					default:
						break;
				}
				data.price = getPpiValue();
				break;
			case 'protect':
				data.item_id = '311_961';
				data.item_name = 'Protect';
				data.item_category = 'Versicherung';
				data.item_category2 = 'Protect';
				data.price = getProtectValue();
				const protect = storeInsuranceProtect ?? null;
				if (protect && (protect === 'standard' || protect === 'premium' || protect === 'basic')) {
					if (protect === 'standard') {
						data.item_category3 = 'Standard';
					} else if (protect === 'premium') {
						data.item_category3 = 'Premium';
					} else {
						data.item_category3 = 'Basic';
					}
				}
				break;
			default:
				data = null;
		}
		return data;
	};

	const ecommercePush = (event, customFlow, customData) => {
		if (process.env.REACT_APP_ENABLE_ECOMMERCE_TRACKING === 'true' && event) {
			try {
				const data = customData ?? [getEcommerceData(customFlow)];
				const tagManagerArgs = {
					dataLayer: {
						event: event,
						ecommerce: {
							currency: 'EUR',
							items: data
						}
					}
				};
				if (storeApplicationId) {
					tagManagerArgs.dataLayer.ecommerce.transaction_id = storeApplicationId;
				}
				if (customFlow === 'ppi') {
					if (event === 'remove_from_cart') {
						tagManagerArgs.dataLayer.ecommerce.value = data.price;
					} else {
						tagManagerArgs.dataLayer.ecommerce.value = getPpiValue();
					}
				} else if (customFlow === 'protect') {
					if (event === 'remove_from_cart') {
						tagManagerArgs.dataLayer.ecommerce.value = data.price;
					} else {
						tagManagerArgs.dataLayer.ecommerce.value = getProtectValue();
					}
				} else if (isCreditSubType()) {
					tagManagerArgs.dataLayer.ecommerce.value = getTotalAmount();
				}
				else if (getCurrentFlow() === C.FRONTEND.SPARBRIEF && storeInvestmentAmount) {
					tagManagerArgs.dataLayer.ecommerce.value = storeInvestmentAmount;
				}
				TagManager.dataLayer(tagManagerArgs);
			} catch (err) {
				console.log(`Google Tag Manager: ${event} push on dataLayer failed`);
			}
		}
	};

	const ecommerceAddToCart = (renew = false, customFlow = null) => {
		if (process.env.REACT_APP_ENABLE_ECOMMERCE_TRACKING === 'true') {
			const event = 'add_to_cart';
			const data = getEcommerceData(customFlow);
			if (data) {
				const existingData = find(storeCart, { item_id: data.item_id });
				if (existingData) {
					if (renew && existingData) {
						dispatch(removeFromCart(existingData.item_id));
						ecommercePush('remove_from_cart', customFlow, existingData);

						dispatch(addToCart(data));
						ecommercePush(event, customFlow);
					}
				} else {
					dispatch(addToCart(data));
					ecommercePush(event, customFlow);
				}
			}
		}
	};

	const ecommerceRemoveFromCart = (customFlow = null) => {
		if (process.env.REACT_APP_ENABLE_ECOMMERCE_TRACKING === 'true') {
			const event = 'remove_from_cart';
			const data = getEcommerceData(customFlow);
			if (data) {
				const existingData = find(storeCart, { item_id: data.item_id });
				if (existingData) {
					dispatch(removeFromCart(existingData.item_id));
					ecommercePush(event, customFlow, existingData);
				}
			}
		}
	};

	const ecommerceBeginCheckout = () => {
		if (process.env.REACT_APP_ENABLE_ECOMMERCE_TRACKING === 'true') {
			const event = 'begin_checkout';
			const data = getEcommerceData();
			if (data) {
				if (!(window.dataLayer && find(window.dataLayer, { event: event, values: { item_id: data.item_id } }))) {
					ecommercePush(event);
				}
			}
		}
	};

	const ecommerceAddShippingInfo = () => {
		if (process.env.REACT_APP_ENABLE_ECOMMERCE_TRACKING === 'true') {
			const event = 'add_shipping_info';
			ecommercePush(event);
		}
	};

	const ecommerceAddPaymentInfo = () => {
		if (process.env.REACT_APP_ENABLE_ECOMMERCE_TRACKING === 'true') {
			const event = 'add_payment_info';
			ecommercePush(event);
		}
	};

	const ecommercePurchase = () => {
		if (process.env.REACT_APP_ENABLE_ECOMMERCE_TRACKING === 'true') {
			const data = getEcommerceData();
			if (data) {
				if (isCreditSubType()) {
					const existingData = find(storeCart, { item_id: data.item_id });
					if (existingData && (existingData.price !== storeCreditAmount || existingData.item_variant !== storeCreditRuntime)) {
						ecommerceAddToCart(true);
					}
				}
				setTriggerECommercePurchase(true);
			}
		}
	};

	const finishEcommercePurchase = () => {
		if (process.env.REACT_APP_ENABLE_ECOMMERCE_TRACKING === 'true') {
			const event = 'purchase';
			ecommercePush(event, null, storeCart);
		}
	};

	return {
		initConsent,
		initGtm,
		openCookieLayer,
		setApplicationInfo,
		setApplicationInfoGtmStatus,
		setApplicationInfoGtmSubStatus,
		virtualPageLoad,
		continueValid,
		continueInvalid,
		goBack,
		bcoResults,
		error,
		dacError,
		getEcommerceData,
		ecommercePush,
		ecommerceAddToCart,
		ecommerceRemoveFromCart,
		ecommerceBeginCheckout,
		ecommerceAddShippingInfo,
		ecommerceAddPaymentInfo,
		ecommercePurchase
	};
};
