import React from 'react';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useCreditUtils } from 'utils/credit/credit';
import { useNumberUtils } from 'utils/number/number';

import { Text, Title } from 'components/Atoms/Text';
import Interest from 'components/Atoms/Partials/Values/Interest';
import PpiValue from 'components/Atoms/Partials/Values/PpiValue';
import ProtectValue from 'components/Atoms/Partials/Values/ProtectValue';
import FixedBorrowingRate from 'components/Atoms/Partials/Values/FixedBorrowingRate';
import EffectiveYearlyInterestRate from 'components/Atoms/Partials/Values/EffectiveYearlyInterestRate';
import NetAmount from 'components/Atoms/Partials/Values/NetAmount';
import TotalAmount from 'components/Atoms/Partials/Values/TotalAmount';
import MonthlyRate from 'components/Atoms/Partials/Values/MonthlyRate';
import SvgInsurance from 'components/Atoms/SVG/Icons/SvgInsurance';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';

import { useDispatch, useSelector } from 'react-redux';
import { updateSidebarExtensionActive } from 'store/App/actions';

import styles from './CreditCalculator.module.scss';
import ToggleLink from 'components/Atoms/Partials/ToggleLink/ToggleLink';

const CreditCalculatorMonthlyRate = () => {
	const { getEffectiveYearlyInterestRate } = useCreditUtils();
	const { formatMoneyValue } = useNumberUtils();

	const dispatch = useDispatch();
	const storeSidebarExtensionActive = useSelector((state => state.app.sidebarExtensionActive));
	const storeFirstRateDate = useSelector((state => state.app.firstRateDate));
	const storeHasPpi = useSelector((state => state.insurance.hasPpi));
	const storeProtect = useSelector((state => state.insurance.protect));

	const toggleExpander = () => {
		dispatch(updateSidebarExtensionActive(!storeSidebarExtensionActive));
	};

	return (
		<>
			<div className={styles.totalValueBox}>
				<div className={classNames(styles.monthlyRateText)}>
					<Text size="l" color="light">{m('monthlyRate.label', 'fields')}</Text>
				</div>
				<div
					className={classNames(styles.monthlyRate)}
					data-testid="monthly-rate-value"
				>
					{storeHasPpi && (
						<div className={styles.iconShield}>
							<SvgInsurance />
						</div>
					)}
					<Title mb><MonthlyRate isDecimal /> €</Title>
				</div>
				<ToggleLink
					active={storeSidebarExtensionActive}
					setActive={() => { toggleExpander(); }}
					size="l"
					tabindex={99}
				>
					{storeSidebarExtensionActive
						? m('closeCreditDetails.label', 'fields')
						: m('openCreditDetails.label', 'fields')}
				</ToggleLink>
			</div>

			<div
				className={classNames(styles.detailsWrapper, {
					[styles.detailsWrapperActive]: storeSidebarExtensionActive
				})}
			>
				{storeHasPpi && (
					<div className={styles.coverageBox}>
						<div className={styles.iconShield}>
							<SvgInsurance />
						</div>
						<div className={styles.coverageBoxText}>
							<Text size="l" color="highlight">{m('pages.insurance.ppi.body.death.title', 'global')}</Text>
						</div>
					</div>
				)}

				<div className={styles.dataRow}>
					<Text size="m">
						<strong>{m('interest.label', 'fields')}</strong>
					</Text>
					<div className={styles.spacer} />
					<Text size="m">
						<Interest isDecimal/> €
					</Text>
				</div>

				{storeHasPpi && (
					<div className={styles.dataRow}>
						<Text size="m">
							<strong>{m('paymentProtectionInsurance.labelLong', 'fields')}</strong>
						</Text>
						<div className={styles.spacer} />
						<Text size="m">
							<PpiValue isDecimal/> €
						</Text>
					</div>
				)}

				{storeProtect && (
					<div className={styles.dataRow}>
						<Text size="m">
							<strong>{m('protect.label', 'fields')}</strong>
						</Text>
						<div className={styles.spacer} />
						<Text size="m">
							<ProtectValue/> €
						</Text>
					</div>
				)}

				<div className={styles.dataRow}>
					<Text size="m">
						<strong>{m('fixedBorrowingRate.label', 'fields')}</strong>
					</Text>
					<div className={styles.spacer} />
					<Text size="m">
						<FixedBorrowingRate isDecimal threeDigitsAfterComma /> %
					</Text>
				</div>

				<div className={styles.dataRow}>
					<Text size="m">
						<strong>{m('effectiveYearlyInterest.label', 'fields')}</strong>
					</Text>
					<div className={styles.inlineTooltipIcon}>
						<TooltipIcon
							text={
								<>{m('effectiveYearlyInterest.description', 'fields', {interest: formatMoneyValue(getEffectiveYearlyInterestRate(), true)})}</>
							}
						/>
					</div>
					<div className={styles.spacer} />
					<Text size="m">
						<EffectiveYearlyInterestRate isDecimal/> %
					</Text>
				</div>

				<div className={styles.dataRow}>
					<Text size="m">
						<strong>{m('netLoanAmount.label', 'fields')}</strong>
					</Text>
					<div className={styles.spacer} />
					<Text size="m">
						<NetAmount isDecimal/> €
					</Text>
				</div>

				<div className={styles.dataRow}>
					<Text size="m">
						<strong>{m('totalAmount.label', 'fields')}</strong>
					</Text>
					<div className={styles.spacer} />
					<Text size="m">
						<TotalAmount isDecimal/> €
					</Text>
				</div>

				{storeFirstRateDate && (
					<div className={styles.dataRow}>
						<Text size="m">
							<strong>{m('firstRate.date', 'fields')}</strong>
						</Text>
						<div className={styles.spacer} />
						<Text size="m">
							{storeFirstRateDate}
						</Text>
					</div>
				)}
			</div>
		</>
	);
};
export default CreditCalculatorMonthlyRate;
