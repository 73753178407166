import axios from 'axios';
import C from 'constants/Constants';
import { useUtils } from 'utils/utils';
import { useApiUtils } from 'utils/api/api';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { useDispatch, useSelector } from 'react-redux';
import { updateAppLoading } from 'store/App/actions';
import { initDigitalAccountCheck, dacProcessResultSuccess } from 'store/DigitalAccountCheck/actions';
import { onUpdateFinancialStatus } from 'store/FinancialStatus/actions';

export const useDacApi = () => {
	const { getCurrentFlow } = useUtils();
	const { getApiBaseURL, startApiCall, stopApiCall, checkUnauthorized } = useApiUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid, error } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeAccessToken = useSelector((state => state.app.accessToken));
	const storeReferenceIban = useSelector((state => state.bankAccount.referenceIban));
	const storeDacTransaction = useSelector((state => state.digitalAccountCheck.transaction));

	const getAccountScreeningInitSession = async (isReminder = false, redoInitCall = false) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		await axios
			.post(getApiBaseURL() + `/account-screening/init-session`, {
				registrationToken: storeAccessToken ?? null,
				iban: storeReferenceIban ?? null,
				firstOrSecondApplicant: 'first-applicant',
				redoInitCall: !!redoInitCall
			}, {
				headers: {
					'Authorization': process.env.REACT_APP_DEFAULT_AUTH_TOKEN
				}
			})
			.then((response) => {
				onAccountScreeningInitSessionSuccess(response.data);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'GET /account-screening/init-session call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				goToPage(currentFlow + C.ROUTES.DIGITAL_ACCOUNT_CHECK_FAILURE + (isReminder ? '/reminder' : ''));
			});
	};

	const onAccountScreeningInitSessionSuccess = (data) => {
		if (data && data.sessionKey && data.transaction) {
			dispatch(initDigitalAccountCheck({
				sessionKey: data.sessionKey,
				transaction: data.transaction
			}));
		}
		stopApiCall();
		dispatch(updateAppLoading('none'));
	};

	const getAccountScreeningProcessResult = async (
		calledFromBCO = false,
		oID = null,
		isReminder = false,
		onDacResponseReceived = {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('DAC'));

		if (storeDacTransaction) {
			let url;
			if (calledFromBCO && oID) {
				url = getApiBaseURL() + `/account-screening/process-result/${storeDacTransaction}/${oID}`;
			} else {
				url = getApiBaseURL() + `/account-screening/process-result-applicant/${storeDacTransaction}/first-applicant`;
			}

			await axios
				.get(url, {
					headers: {
						'Authorization': process.env.REACT_APP_DEFAULT_AUTH_TOKEN
					}
				})
				.then((response) => {
					onAccountScreeningProcessResultSuccess(
						response.data,
						calledFromBCO,
						isReminder,
						onDacResponseReceived
					);
				})
				.catch((err) => {
					const status = err?.response?.status ?? 500;
					error(status, 'GET /account-screening/process-result call failed');
					const unauthorized = checkUnauthorized(status);
					if (unauthorized) return null;

					stopApiCall();
					dispatch(updateAppLoading('none'));
					continueInvalid();
					goToPage(currentFlow + C.ROUTES.DIGITAL_ACCOUNT_CHECK_FAILURE + (isReminder ? '/reminder' : ''));
				});
		} else {
			stopApiCall();
			dispatch(updateAppLoading('none'));
			continueInvalid();
			goToPage(currentFlow + C.ROUTES.DIGITAL_ACCOUNT_CHECK_FAILURE + (isReminder ? '/reminder' : ''));
		}
	};

	const onAccountScreeningProcessResultSuccess = (
		data,
		calledFromBCO = false,
		isReminder = false,
		onDacResponseReceived = () => {}
	) => {
		if (!calledFromBCO) {
			dispatch(dacProcessResultSuccess({
				status: data.dacStatus
			}));
			continueValid();
			if (data.dacStatus === C.DAC_STATUS.GREEN) {
				dispatch(onUpdateFinancialStatus({
					income: data.dacIncome,
					additionalIncome: data.dacAdditionalIncome,
					expenditure: data.dacLivingCosts
				}));
				continueValid();
				goToPage(currentFlow + C.ROUTES.DIGITAL_ACCOUNT_CHECK_SUCCESS + (isReminder ? '/reminder' : ''));
			} else if (!isReminder && data.dacStatus === C.DAC_STATUS.YELLOW && !data.dacIncome) {
				continueValid();
				goToPage(currentFlow + C.ROUTES.DIGITAL_ACCOUNT_CHECK_IBAN);
			} else {
				continueInvalid();
				goToPage(currentFlow + C.ROUTES.DIGITAL_ACCOUNT_CHECK_FAILURE + (isReminder ? '/reminder' : ''));
			}
		} else {
			onDacResponseReceived();
		}
		stopApiCall();
		dispatch(updateAppLoading('none'));
	};

	return {
		getAccountScreeningInitSession,
		getAccountScreeningProcessResult
	};
};
